<template>
  <cui-acl  :roles="['talkmoreB2Csettings']" redirect>
    <div class="kit__utils__heading">
      <h5>
        <span class="mr-3">Transaksjoner</span>
        <!-- <a-button style="float: right;" @click="downloadData()">
          Last ned informasjon
        </a-button> -->
      </h5>
    </div>
    <!-- filters -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <a-form-item label="Type" required>
              <a-select :options="importTypes" style="width: 100%" @change="getData()" v-model="filter.type" />
            </a-form-item>
          </div>
          <div class="col-md-3">
            <a-form-item label="Prosjekt" required>
              <a-select :options="projects" style="width: 100%"  @change="getData()" v-model="filter.project"/>
            </a-form-item>
          </div>
          <div class="col-md-2">
            <a-form-item label="Status" required>
              <a-select style="width: 100%"  @change="getData()" v-model="filter.status"/>
            </a-form-item>
          </div>
          <div class="col-md-2">
            <a-form-item label="År" required>
              <a-select :options="years" style="width: 100%"  @change="getData()" v-model="filter.year"/>
            </a-form-item>
          </div>
          <div class="col-md-2">
            <a-form-item label="Måned" required>
              <a-select :options="months" style="width: 100%"  @change="getData()" v-model="filter.month"/>
            </a-form-item>
          </div>
          <!-- <div class="col-md-12">
            <a-form-item label="Data" required>
              <a-textarea placeholder="Notater" :rows="4" v-model="input" @change="convertInput" />
            </a-form-item>
          </div> -->
        </div>
      </div>
    </div>
    <a-table bordered :data-source="dataSource" :columns="columns">
      <template slot="name" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record.key, 'name', $event)" />
      </template>
      <template slot="type" slot-scope="text, record">
        <dropdown-cell :text="text" @change="onCellChange(record.key, 'type', $event)" :options="[{value: 'main', text: 'Hoved'}, {value: 'extra', text: 'Mersalg'}]" />
      </template>
      <template slot="project" slot-scope="text, record">
        <dropdown-cell :text="text" @change="onCellChange(record.key, 'project', $event)" :options="projects" />
      </template>
      <template slot="points" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record.key, 'points', $event)" />
      </template>
      <template slot="status" slot-scope="text, record">
        <dropdown-cell :text="text" @change="onCellChange(record.key, 'status', $event)" :options="[{value: 'active', text: 'Aktiv'}, {value: 'inactive', text: 'Inaktiv'}]" />
      </template>
      <template slot="sequence" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record.key, 'sequence', $event)" />
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-popconfirm
          v-if="dataSource.length"
          title="Sure to delete?"
          @confirm="() => onDelete(record.key)"
        >
          <a href="javascript:;">Slett</a>
        </a-popconfirm>
      </template>
    </a-table>
    <a-button class="editable-add-btn mt-3" @click="handleAdd">
      Legg til
    </a-button>
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import { firestore } from '@/services/firebase'
import { mapState } from 'vuex'
import exportFromJSON from 'export-from-json'
import EditableCell from '@/components/custom/visual/editableCell'
import DropdownCell from '@/components/custom/visual/dropdownCell'
import moment from 'moment'
import dash from 'lodash'

function onChange(pagination, filters, sorter) {
  console.log('params', pagination, filters, sorter)
}

const importTypes = [
  {
    value: 'sale',
    label: 'Salg',
  },
]

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
    EditableCell,
    DropdownCell,
  },
  data() {
    return {
      importTypes,
      projects: [],
      years: [],
      months: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dataSource: [],
      count: 0,
      filter: {
        type: '',
        project: '',
        year: String(moment().year()),
        month: String(moment().month()),
      },
      columns: [
        {
          title: 'EmployeeID',
          dataIndex: 'EeployeeID',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'project',
          dataIndex: 'project',
          scopedSlots: { customRender: 'project' },
        },
        {
          title: 'department',
          dataIndex: 'department',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'type',
          dataIndex: 'type',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'amount',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'name' },
        },
      ],
    }
  },
  methods: {
    moment,
    onChange,
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.dataSource = dataSource
        firestore.collection('payrollTransactions').doc(target.id)
          .set({
            name: target.name,
            points: Number(target.points),
            sequence: Number(target.sequence),
            status: target.status,
            family: Boolean(target.family === 'true'),
            type: target.type,
          })
        this.$forceUpdate()
      }
    },
    onDelete(key) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find(obj => obj.key === key)
      console.log(target)
      firestore.collection('payrollTransactions').doc(target.id).delete()
      this.dataSource = dataSource.filter(obj => obj.key !== key)
      this.$forceUpdate()
    },
    handleAdd() {
      const { dataSource } = this
      this.count = this.count + 1
      const newData = {
        key: this.count,
        name: `Ny Produkt ${this.count + 1}`,
        type: 'main',
        family: 'false',
        points: 0,
        status: 'inactive',
      }
      firestore.collection('payrollTransactions').add(newData)
        .then(res => {
          const fireData = newData
          fireData.id = res.id
          fireData.points = String(fireData.points)
          this.dataSource = [...dataSource, fireData]
          console.log(fireData)
          this.$forceUpdate()
        })
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    downloadData() {
      const data = this.dataSource
      const fileName = 'product data'
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      exportFromJSON({ data, fileName, exportType, delimiter })
    },
    getData() {
      const { filter } = this
      console.log(filter)
      firestore.collection('payrollTransactions')
        .where('year', '==', filter.year)
        .where('month', '==', filter.month)
        .where('type', '==', filter.type || '')
        .where('project', '==', filter.project || '')
        .orderBy('employeeID', 'asc')
        .get().then(snapshot => {
          this.dataSource = []
          this.count = 0
          snapshot.forEach(doc => {
            const data = doc.data()
            data.id = doc.id
            this.dataSource.push(data)
            this.count += 1
          })
        })
    },
    generateMonths() {
      moment.locale()
      this.months = Array.from({ length: 12 }, (_, i) => ({
        value: String(i + 1),
        label: String(dash.capitalize(moment.months(i))),
      }))
      this.years = Array.from({ length: 12 }, (_, i) => ({
        value: String(i + 2022),
        label: String(i + 2022),
      }))
    },
  },
  mounted() {
    // create a search bar on top to filter any value for user const res = arr.filter(obj => Object.values(obj).some(val => val.includes(search)));
    this.getData()
    this.generateMonths()
    firestore.collection('settings').doc('projects').collection('projects')
      .where('company', '==', 'Skycall')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const project = {
            value: doc.id,
            text: doc.data().name,
            label: doc.data().name,
          }
          this.projects.push(project)
        })
      })
  },
}
</script>
